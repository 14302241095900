/**
 * Created by antti on 16.11.2015.
 */

// Köyhän miehen table filter
$(document).ready(function () {
    (function ($) {
        $('#filter').keyup(function () {
            var rex = new RegExp($(this).val(), 'i');
            $('.searchable tr').hide();
            $('.searchable tr').filter(function () {
                return rex.test($(this).text());
            }).show();

        })
    }(jQuery));

    // Ja lukituksen varmistus, ei viitsinyt tehdä ihan kaikelle omaa tiedostoaan
    $("#lockbutton").on("click", function(e) {
       if(confirm("Haluatko varmasit lukita vastaukset?"))
       {
           return true;
       }
       return false;
    });
});