/**
 * Created by antti on 14.11.2015.
 */
$.fn.editable.defaults.emptytext = 'Tyhjä';
$.fn.editable.defaults.ajaxOptions =
{
    headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
    dataType: 'json'
};
$.fn.editable.defaults.params = function(params)
{
    var data = {};
    data['pk'] = params.pk;
    data['value'] = params.value;
    data['name'] = params.name;
    data['originalValue'] = $(this).text();
    return data;
};