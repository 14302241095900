/**
 * Created by antti on 16.11.2015.
 */
// Enable pusher logging - don't include this in production
//Pusher.log = function(message) {
//    if (window.console && window.console.log) {
//        window.console.log(message);
//    }
//};

var pusher = new Pusher('ebd13ac8fb264d9ccb3c', {
    encrypted: true
});

// Notify.js global conf
$.notify.defaults({
    position: 'bottom right',
    className: 'info',
});